import React, {useState} from "react";
import PropTypes from "prop-types";
import {debounce} from "lodash";
import companyPropTypes from "lib/props-schema/company-schema";
import {DateDisplay, EmployeeSearchInput, LayoutContainer, PageFilters, Table} from "components";
import {SelectInputRow} from "components/forms";
import {useEmployeeList} from "lib/api-endpoints";

import styles from "./EmployeeList.module.scss";

/**
 * Gets an object of formatted employee data
 *
 * @param {object} employee The employee to format
 *
 * @returns {object} The formatted employee
 */
const getEmployeeLink = (employee) => ({
    text: (employee.forename_1 && employee.surname)
        ? `${employee.surname}, ${employee.forename_1}`
        : `ID: ${employee.id}`,
    link: `/employees/${employee.id}`,
});

/**
 * Checks if the employee has an email to show and shows backup if not
 *
 * @param {object} email The employee email to format
 *
 * @returns {object} The formatted employee email
 */
const getEmployeeEmail = (email) => (email ? email : '-');

const totalPerPage = 10;

/**
 * Gets the formatted employees
 *
 * @param {array} employees The employees to format
 *
 * @return {array} An array of formatted employees
 */
const getFormattedEmployees = (employees) => employees?.map((employee) => {
    const {start_date: startDate} = employee;

    return {
        id: employee.id,
        name: getEmployeeLink(employee),
        "payroll_no": employee?.payroll_no ? employee?.payroll_no.toString() : '-',
        email: getEmployeeEmail(employee?.emails?.home),
        company: employee.company ?? "-",
        site: employee?.sites?.map(({name}) => name).join(", ") || "-",
        "start_date": {
            component: (startDate)
                ? <DateDisplay data={startDate} output="DD-MM-YYYY" />
                : "-",
        },
    };
});

/**
 * Gets the headings for the employee list
 *
 * @param {boolean} withCompany Whether to include the company heading
 *
 * @returns {object} The headings for the employee list
 */
const getHeadings = (withCompany) => {
    const headings = {
        name: "Name",
        "payroll_no": "Payroll Ref",
        email: "Email",
        company: "Company",
        site: "Site",
        "start_date": "Start Date",
    };

    if (!withCompany) {
        delete headings.company;
    }

    return headings;
};

/**
 * Gets the handle search function with a debounce
 *
 * @param {function} setSearchTerm The search term setter
 * @param {function} setEmployeeListPage The employee list page setter
 * @param {number} debounceNumber The debounce number for the search
 *
 * @returns {function} The handle search function
 */
const getHandleSearch = (setSearchTerm, setEmployeeListPage, debounceNumber = 500) => {
    /**
     * Debounces prop onSearch call when typing in the search
     */
    const callOnSearch = debounce((value) => {
        if (value.length >= 3) {
            setSearchTerm(value);
            setEmployeeListPage(1);
        }

        if (!value.length) {
            setSearchTerm(0);
        }
    }, debounceNumber);

    return (e) => {
        callOnSearch(e?.target?.value);
    };
};

/**
 * Renders the employees for the employee list based on supplied props
 *
 * @param {object} company The company filter to get the employees for the company selected
 * @param {boolean} refresh Refresh the employee list
 *
 * @returns {React.Component} Employee list
 */
const EmployeeList = ({ company, refresh }) => {
    const [page, setEmployeeListPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState();
    const handleSearch = getHandleSearch(setSearchTerm, setEmployeeListPage);

    const listParams = {
        page,
        refresh,
        perPage: totalPerPage,
        searchString: searchTerm,
    };

    const headings = getHeadings(!company);

    if (company) {
        listParams.companyIds = [company.id];
    }

    const { data: employeeData, isLoaded } = useEmployeeList(listParams);

    const tableData = getFormattedEmployees(employeeData?.data);

    return (
        <>
            <PageFilters>
                <EmployeeSearchInput onChange={handleSearch} transparent />
                <div className={styles.filterContainer}>
                    <SelectInputRow
                        aria-label="employee filter"
                        options={[
                            {
                                display: "All",
                                value: 0,
                            },
                            {
                                display: "Current",
                                value: 1,
                            },
                            {
                                display: "Leavers",
                                value: 2,
                            },
                        ]}
                    />
                </div>
            </PageFilters>
            <LayoutContainer>
                <Table
                    data={tableData}
                    skeletonConfig={{
                        rowCount: totalPerPage,
                        rowConfig: [
                            { key: 'employee-info' },
                        ],
                    }}
                    dataLoaded={isLoaded}
                    headings={headings}
                    noDataText="No Employees"
                    pagination={{
                        totalPages: employeeData?.meta?.last_page,
                        currentPage: page,
                        onPageChange: setEmployeeListPage,
                    }}
                />
            </LayoutContainer>
        </>
    );
};

EmployeeList.propTypes = {
    company: companyPropTypes,
    refresh: PropTypes.bool,
};

EmployeeList.defaultProps = {
    company: null,
    refresh: false,
};

export default EmployeeList;
